
.mobile-show,
.mobile-show-inline,
.mobile-show-inline-block {
	display: none;
}

.mobile-only-show,
.mobile-only-show-inline,
.mobile-only-show-inline-block {
	display: none;
}

/* ==== Screen over the standard 930px === */
@media(min-width: 930px) {
	.video-page {
		#video-player-bg {
			#html5video.embed-responsive,
			#player {
				height: 476px;
				padding-bottom: 0;
				embed,
				.embed-responsive-item {
					height: 476px;
					position: static;
				}
			}
		}
		.player-enlarged {
			#video-player-bg {
				#html5video.embed-responsive,
				#player {
					height: auto;
					padding-bottom: 40%;
					@media(max-aspect-ratio: ~"1280/680") {
						padding-bottom: 45%;
					}
					@media(max-aspect-ratio: ~"1280/760") {
						padding-bottom: 50%;
					}
					@media(max-aspect-ratio: ~"1280/840") {
						padding-bottom: 56.25%;
					}
					@media(max-aspect-ratio: ~"1280/960") {
						padding-bottom: 62.5%;
					}
					embed,
					.embed-responsive-item {
						height: 100%;
						position: absolute;
					}
				}
			}
		}
	}
}

/* ==== Max screen size ==== */
@media(min-width: 1280px) {
	.video-page .player-enlarged {
		#video-player-bg {
			#html5video.embed-responsive,
			#player {
				@media(min-height: 680px) {
					padding-bottom: 45%;
				}
				@media(min-height: 760px) {
					padding-bottom: 50%;
				}
				@media(min-height: 840px) {
					padding-bottom: 55%;
				}
			}
		}
	}
}

@media (max-width: @screen-sm) {
	.mobile-show {
		display: block;
	}
	.mobile-show-inline {
		display: inline;
	}
	.mobile-show-inline-block {
		.inline-block;
	}

	.mobile-hide,
	.mobile-hide-inline {
		display: none;
	}
	
	.language-switcher-popup {
		display: fixed;
		font-size: 16px;
		a {
			padding: 7px 20px;
			font-weight: normal;
		}
	}
	#main-tower {
		display: none;
	}
	.video-page {
		#video-right {
			display: none;
		}
		#video-player-bg {
			#html5video.embed-responsive,
			#player {
				padding-bottom: 40%;
				@media(max-aspect-ratio: ~"640/340") {
					padding-bottom: 45%;
				}
				@media(max-aspect-ratio: ~"640/380") {
					padding-bottom: 50%;
				}
				@media(max-aspect-ratio: ~"640/420") {
					padding-bottom: 56.25%;
				}
				@media(max-aspect-ratio: ~"640/480") {
					padding-bottom: 62.5%;
				}
				@media(max-aspect-ratio: ~"640/640") {
					padding-bottom: 80%;
				}
			}
		}
		#video-views-votes {
			margin: 6px 0 6px 2px;
			.nb-views {
				margin-right: 4px;
				font-size: 12px;
				strong {
					font-size: 18px;
				}
			}
			.vote-actions {
				margin-right: 4px;
				.btn {
					padding: 8px 10px 2px;
				}
			}
			.rating-bar {
				margin-right: 4px;
				.pgs-bar {
					width: 50px;
				}
			}
			.rating-box {
				font-size: 17px;
				line-height: 1.5;
				vertical-align: bottom;
			}
			.x-overlay .x-body {
				padding: 7px 20px;
			}
		}
	}

	&.old-ie .video-page #video-player-bg #player {
		height: 320px;
		padding-bottom: 0;
		embed,
		.embed-responsive-item {
			height: 320px;
		}
	}
}

@media (max-width: 640px) {
	.adframe-footer {
		table table td {
			display: block;
			float: left;
			width: 50%;
			text-align: center;
		}
	}
	.video-page {
		#video-views-votes .nb-views {
			.views-full {
				display: none;
			}
			.nb-views-number {
				display: inline;
			}
		}
	}
}

@media (max-width: 480px) {
	.mobile-only-show {
		display: block;
	}
	.mobile-only-show-inline {
		display: inline;
	}
	.mobile-only-show-inline-block {
		.inline-block;
	}
	.mobile-only-hide {
		display: none;
	}
	.video-page {
		#video-views-votes {
			.vote-actions .btn {
				padding-left: 7px;
				padding-right: 7px;
			}
			.rating-bar {
				display: none;
			}
			.rating-box {
				font-size: 12px;
				line-height: 1.15;
				text-align: center;
			}
		}
	}
}

@media (max-width: 412px) {
	.thumb-block {
		width: 100%;
		margin-right: 0;
		padding-right: 4px;
	}
}


.btn {
	margin: 0 2px 4px 0;
	border-width: 1px 1px 4px;
	padding-bottom: 3px;
	box-shadow: 1px 1px 1px #bbb;
	transition: box-shadow ease 0.2s, background ease 0.2s, border ease 0.2s;
	&:hover,
	&:active,
	.active {
		box-shadow: 1px 1px 2px #999;
	}
	&.btn-link {
		box-shadow: none;
	}
	&.btn-xs {
		border-bottom-width: 1px;
		padding-bottom: 1px;
	}
	&.btn-lg {
		font-size: 15px;
		padding-bottom: 10px;
	}
	&.light-label {
		font-weight: normal;
	}
	&.wrap {
		white-space: normal;
	}
	.btn-group & {
		margin-right: 0;
		&:last-child {
			margin-right: 2px;
		}
	}
}

.icon {
	.inline-block;
	background-position: left top;
	background-repeat: no-repeat;
	background-image: url('../../../../v-00000000001/v3/img/skins/pornorama/icons-sprite.png');
	/* autoprefixer: off */
	background-image: linear-gradient(transparent, transparent), url('../../../../v-00000000001/v3/img/skins/pornorama/icons-sprite.svg');
	/* autoprefixer: on */
	.btn-xs &,
	.btn-sm & {
		vertical-align: bottom
	}
	&.thumb-up {
		width: 15px;
		height: 16px;
		background-position: 0 0;
		&.red {
			background-position: -15px 0;
		}
		&.grey,
		.disabled & {
			background-position: -30px 0;
		}
		&.white,
		.btn:hover &.black,
		.btn:hover &.red {
			background-position: -45px 0;
		}
		&.white:hover {
			background-position: -15px 0;
		}
	}
	&.thumb-down {
		width: 15px;
		height: 16px;
		background-position: -60px 0;
		&.red {
			background-position: -75px 0;
		}
		&.grey,
		.disabled & {
			background-position: -90px 0;
		}
		&.white,
		.btn:hover &.black,
		.btn:hover &.red {
			background-position: -105px 0;
		}
	}
}



.pornorama-label-mixin(@video-hd-mark-height) {
	font-size: round(@video-hd-mark-height * 5 / 6);
	line-height: @video-hd-mark-height;
	height: @video-hd-mark-height;
	padding: 0 round(@video-hd-mark-height / 4);
}

.video-hd-mark {
	.inline-block;
	background: @yellow-theme;
	color: #000;
	font-weight: bold;
	text-align: center;
	border-radius: 1px;
	.pornorama-label-mixin(22px);
	vertical-align: text-top;
	.thumb-inside & {
		.pornorama-label-mixin(14px);
		vertical-align: baseline;
		position: absolute;
		top: 3px;
		right: 3px;
	}
}

.mozaique {
    overflow: hidden;
	margin: 0 0 4px 4px;
}

.thumb-block {
    position: relative;
    float: left;
    text-align: center;
	width: 402px;
	margin: 4px 4px 4px 0;
    .thumb-inside {
		position: relative;
		display: block;
		height: 0;
		padding: 0;
		overflow: hidden;
		margin-bottom: 1px;
		padding-bottom: 75%;
    }
    .thumb {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
        a {
            display: block;
			position: relative;
			overflow: hidden;
			zoom: 1;
			border: 1px solid #000;
			min-height: 99%;
        }
        img {
            width: 100%;
			vertical-align: bottom;
			&.hovered {
				box-sizing: content-box;
				position: absolute;
				width: 300%;
				height: 300%;
				top: 0;
				left: 0;
				margin-left: 0%;
				margin-top: 0%;
			}
        }
    }
	&:hover {
		.thumb a {
			border-color: #fff;
		}
	}
    p {
        margin: 0;
        line-height: 14px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		a {
			font-weight: bold;
		}
	}
}
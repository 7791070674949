
.http-error-page {
	#content {
		padding: 50px 20px;
	}
	h1, h2, h3, h4 {
		margin-left: 0;
		text-align: center;
	}
}

#header {
	color: #fff;
	font-size: 18px;
	background: #0F4BFF;
	border-bottom: 2px solid #f0b500;
	overflow: hidden;
	header {
		border-bottom: 1px solid #000090;
		overflow: hidden;
	}
	ul {
		float: left;
		margin: 0 20px 0 0;
		a {
			color: #fff;
			font-size: 15px;
			text-decoration: underline;
		}
		.sep {
			color: #000;
			padding: 0;
		}
	}
	.brand {
		float: right;
		margin-left: 20px;
		margin-right: 5px;
		font-weight: bold;
	}
	#social-links {
		text-align: center;
		height: 26px;
		padding: 1px;
		white-space: nowrap;
		> a {
			vertical-align: sub;
		}
	}
	#language-switcher {
		cursor: pointer;
		span {
			vertical-align: bottom;
			margin-top: -4px;
			margin-bottom: -3px;
		}
	}
}

.language-switcher-popup {
	.x-popup-content {
		padding: 8px 0;
	}
	ul {
		margin: 0;
		padding: 0;
	}
	li {
		display: block;
	}
	a {
		white-space: nowrap;
		text-decoration: none;
		display: block;
		padding: 3px 20px;
		font-weight: bold;
		&:hover {
			text-decoration: undeline;
			background: @yellow-theme;
			color: #000;
			text-decoration: none;
		}
	}
	.flag-small {
		margin-right: 5px;
	}
}

#main-tower {
	float: right;
	width: 182px;
	margin-left: 20px;
	margin-right: 4px;
	p {
		font-size: 11px;
		line-height: 1.25;
		margin: 0 0 4px 0;
		a {
			color: #fff;
			text-decoration: underline;
		}
	}
}

.adframe-tower {
	padding: 1px;
	border: 1px solid #fff;
	background: #0F4BFF;
	> div {
		padding: 2px 3px;
		border: 1px solid #fff;
	}
	a {
		text-decoration: underline;
	}
}

.adframe-footer {
	max-width: 930px;
	padding: 6px;
	margin: 4px auto;
	border: 1px solid #000;
	background: #0F4BFF;
	> div {
		border: 1px solid #fff;
		padding: 6px;
		background: #0035d4;
	}
	a {
		color: #fff;
		text-decoration: underline;
	}
	table {
		width: 100%;
	}
	video {
		vertical-align: middle;
	}
}

// Footer
#ad-footer {
	clear: both;
	margin-top: 14px;
	text-align: center;
	a {
		display:inline-block;
	}
	img,
	video {
		max-width: 100%;
	}
	video {
		vertical-align: middle;
	}
	&.is-filled {
		height: 0;
		margin-left: auto;
		margin-right: auto;
		max-width: 480px;
		
		.is-desktop & {
			max-width: 900px;
			padding-bottom: 27.78%;
			
			& > * {
				display: block;
				max-width: 900px;
				width: 900px;
			}
		}
		
		&.ad-support-mobile,
		&.ad-support-tablet {
			padding-bottom: 33.33%;
			
			& > * {
				display: block;
				max-width: 300px;
				width: 300px;
			}
		}
	}
}
@media (min-width: 900px + (2 * @body-out-padding)) {
	#ad-footer{
		.is-desktop &.is-filled {
			padding-bottom: 250px;
		}
	}
}
@media (min-width: 300px + (2 * @body-out-padding)) {
	#ad-footer{
		&.is-filled {
			&.ad-support-mobile,
			&.ad-support-tablet {
				padding-bottom: ~"min(33.33%, 160px)";
			}
		}
	}
}

.adsby {
	font-size: 12px;
	a {
		color: #fff;
		text-decoration: underline;
	}
}

.footer-slogan {
	margin: 18px 4px;
	font-size: 18px;
	a {
		text-decoration: underline;
	}
	.upload {
		color: #fff;
	}
}

.footer-legal {
	margin: 10px 0;
	text-align: center;
}

#parent-alert {
	max-width: 930px;
	padding: 10px;
	margin: 0 auto;
	border: 2px solid #000;
	text-align: left;
	font-size: 10px;
	color: #fff;
	background: #0035d4;
	a {
		color: rgb(255, 204, 0);
		text-decoration: underline;
		&.dmca {
			color: #fff;
		}
	}
}


.video-page {
	max-width: 1280px;
	margin: 0 auto;
	#header {
		&,
		& a {
			font-size: 20px;
		}
		ul {
			margin-left: 10px;
			li {
				padding-left: 5px;
				margin-left: 5px;
			}
		}
		#language-switcher {
			span {
				margin: 0;
			}
		}
	}
	h2 {
		margin: 0;
		padding: 0 1px;
		background: #F0B500;
		font-size: 18px;
		.duration {
			color: #fff;
		}
	}
	#social-links {
		margin: 2px 0;
		text-align: center;
		height: 26px;
		white-space: nowrap;
		> a {
			vertical-align: sub;
		}
	}
	#video-right {
		float: right;
		width: 36.3%;
		max-width: 338px;
		min-height: 1px;
		text-align: center;
		margin-left: 4px;
		a {
			display:block;
		}
		> *:first-child {
			margin-bottom: 6px;
		}
	}
	.live_model_thumb {
		position: relative;

		img {
			display: block;
			width: 100%;
		}
		
		&:hover {
			img {
				box-shadow: 0 0 0 2px #fff;
			}
		}

		video {
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;	
			position: absolute;
			width: 100%;		
		}
	}
	#video-player-bg {
        overflow: hidden;
		background: #000;
		#html5video.embed-responsive,
		#player {
			overflow: hidden;
			text-align: center;
			color: #000;
			line-height: 0;
			font-size: 18px;
			font-weight: bold;
			padding-bottom: 80.95%; // Ensure aspect ratio with .embed-responsive
		}
		#player {
			.old-ie & {
				height: 476px;
				padding-bottom: 0;
				embed,
				.embed-responsive-item {
					height: 476px;
					position: static;
				}
			}
		}
	}
	.player-enlarged {
		#video-player-bg {
			#html5video.embed-responsive,
			#player {
				height: auto;
				padding-bottom: 40%;
				@media(max-aspect-ratio: ~"1280/680") {
					padding-bottom: 45%;
				}
				@media(max-aspect-ratio: ~"1280/760") {
					padding-bottom: 50%;
				}
				@media(max-aspect-ratio: ~"1280/840") {
					padding-bottom: 56.25%;
				}
				@media(max-aspect-ratio: ~"1280/960") {
					padding-bottom: 62.5%;
				}
				embed,
				.embed-responsive-item {
					height: 100%;
					position: absolute;
				}
			}
		}
		#video-right {
			display: none;
		}
	}
	#html5video {
		&,
		* {
			box-sizing: content-box;
		}
	}
	#video-views-votes {
		float: left;
		margin: 4px 10px 4px 0;
		padding: 11px 5px;
		color: #000;
		background: #FED801;
		white-space: nowrap;
		vertical-align: top;
		.nb-views {
			font-size: 11px;
			margin-right: 10px;
			strong {
				font-size: 16px;
			}
			.nb-views-number {
				display: none;
			}
		}
		.vote-actions {
			vertical-align: super;
			margin-right: 10px;
			.btn {
				margin: 0;
				padding: 4px 6px 0;
				&:first-child {
					border-right: none;
					border-radius: @btn-border-radius-base 0 0 @btn-border-radius-base;
				}
				&:last-child {
					border-radius: 0 @btn-border-radius-base @btn-border-radius-base 0;
				}
			}
		}
		.rating-bar {
			margin-right: 10px;
			.inline-block;
			overflow: hidden;
			font-size: 11px;
			line-height: 12px;
			text-align: right;
			vertical-align: text-bottom;
			.pgs-bar {
				margin-top: 2px;
				background: #DE2600;
				width: 100px;
				height: 4px;
				div {
					width: 50%;
					height: 4px;
					background: #000;
				}
			}
		}
		.rating-box {
			.inline-block;
			font-size: 15px;
			line-height: 1.2;
			font-weight: bold;
			padding: 3px 6px;
			background: #000;
			color: #fff;
			vertical-align: text-bottom;
		}
		.x-overlay .x-body {
			padding: 5px 20px;
		}
	}

	#video-embed {
		overflow: hidden;
		background: #F0B500;
		padding: 2px 5px 3px;
		margin: 4px 0;
		font-size: 14px;
		.form-control {
			font-size: 12px;
			margin: 0;
			padding: 2px 4px;
			color: #000;
			height: auto;
		}
	}
	h3 {
		clear: both;
		margin: 2px;
		text-align: center;
		font-size: 24px;
		a {
			color: #fff;
			text-decoration: underline;
		}
	}
	#parent-alert {
		max-width: 1280px;
	}
	
	
	#related-videos {
		margin: 0 4px;
		overflow: hidden;
		text-align: center;
		.show-more {
			display: block;
			margin: 5px 0 0;
			box-shadow: none;
			overflow: hidden;
		}
		.thumb-block {
			display: inline-block;
			float: none;
			&.after-12,
			&.after-15 {
				display: none;
			}
		}
		&.expanded {
			.show-more {
				display: none;
			}
			.thumb-block {
				&.after-12,
				&.after-15 {
					display: inline-block;
				}
			}
		}
	}
}

.pagination {
    margin: 2px 0;
    overflow: hidden;
	clear: left;
	white-space: nowrap;
    ul {
		margin: 0;
        padding: 0;
        vertical-align: middle;
        li {
            .inline-block;
            padding: 0;
			margin: 0 3px 0 0;
            a {
                .inline-block;
				font-size: 16px;
				line-height: 19px;
                padding: 3px 8px;
                border: 1px solid #000;
                text-decoration: none;
				color:  #fff;
				background: rgb(240, 181, 0);
				&.no-page,
				&.active {
					font-weight: bold;
					border-color: transparent;
					text-decoration: underline;
				}
			}
        }
    }
	&.lighter {
		ul {
			li {
				a {
					background: #fed801;
					border-color: #fff;
					&:hover {
						background: rgb(240, 181, 0);
						border-color: #000;
					}
					&.no-page,
					&.active {
						&, &:hover {
							background: none;
							border-color: transparent;
						}
					}
				}
			}
		}
	}
}
